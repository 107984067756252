<template>
    <b-container class="not-found">
        <b-row>
            <b-col>
                <p class="code">404</p>
                <p class="text">{{ $t('notFound.text') }}</p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        mounted: function() {
            this.$ebus.$emit("nav:hide")
        },
        destroyed: function() {
            this.$ebus.$emit("nav:show")
        }
    }
</script>

<style scoped lang="scss">
    @import 'src/scss/main';

    .not-found {
        text-align: center;
        margin-top: $container-top-margin;

        .code {        
            font-size: 55px;
            font-weight: $font-bold;
            color: var(--primary);
            letter-spacing: 5px;
        }

        .text {
            font-size: 20px;
            font-weight: $font-thin;
            color: $light;
        }
    }
    
</style>